import { graphql } from "gatsby"
import React from "react"
import { Visible } from "react-grid-system"
import HomeHeader from "../components/homeHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MobileSlider from "../components/mobileSlider"
import Slider from "../components/slider"
import Header from "../components/header"
import Footer from "../components/footer"

const IndexPage = ({ data: { page } }) => {
  // console.log(page)
  return (
    <Layout>
      <SEO title="Home" />
      <Visible lg xl xxl>
        <HomeHeader theme="dark" />
        <Slider content={page.pageLink} />
      </Visible>
      <Visible xs sm md>
        <Header />
        <MobileSlider content={page.pageLink} />
        <Footer />
      </Visible>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageItQuery {
    page: datoCmsHomePage {
      pageLink {
        slug
        label
        homeImage {
          url
        }
        id
      }
      id
    }
  }
`
