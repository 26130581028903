import React from "react"
import styled from "styled-components"
import { useFooter } from "../hooks/useFooter"
import DesignTokens from "./designTokens"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Box, Container, Flex, Grid, Text } from "@theme-ui/components"
import { Link } from "gatsby"

const FooterHome = params => {
  const footer = useFooter()
  const contactLinks = footer[0].contactLinks
  // console.log(contactLinks)
  return (
    <Box sx={{ mt: 4, position: "absolute", bottom: 4, maxWidth: ["24rem"] }}>
      <Text as="p" sx={{ fontSize: 0, color: "#999999", mb: 3 }}>
        Winter Services S.r.l. - Via Padergnone 52 <br />
        24050 Grassobbio (BG) - Italy - Partita IVA: 03790640167
      </Text>
      <Box
        sx={{
          textAlign:"left",
          fontSize: 0,
          "*": { marginRight: 3 },
        }}
      >
        <a
          href="https://www.iubenda.com/privacy-policy/47639727"
          target="blank"
          rel="nofollow noopener"
        >
          Privacy Policy
        </a>
        <a
          href="https://www.iubenda.com/privacy-policy/47639727/cookie-policy"
          target="blank"
          rel="nofollow noopener"
        >
          Cookie Policy
        </a>
      </Box>
    </Box>
  )
}

const CustomOutboundLink = styled(OutboundLink)`
  color: ${DesignTokens.colors.primary[500]};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Content = styled.div`
  & > * {
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

export default FooterHome
