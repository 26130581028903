import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"
import logo from "../images/logo-flambe.svg"
import { Link } from "gatsby"
import NavLinks from "./navLinks"

const HomeHeader = ({ theme }) => (
  <Wrapper>
    <Row>
      <Col>
        <Link to="/">
          <img src={logo} alt="Flambé Fine Catering" />
        </Link>
      </Col>
      <Col>
        <NavLinks theme={theme} />
      </Col>
    </Row>
  </Wrapper>
)
const Wrapper = styled.div`
  padding-left: 10%;
  padding-right: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  z-index: 1;
`

export default HomeHeader
