import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Display } from "./typography"
import { motion, AnimatePresence } from "framer-motion"
import DesignTokens from "./designTokens"
import { Box } from "@theme-ui/components"
import FooterHome from "../components/footerHome"

const Slider = ({ content }) => {
  const [currentPage, setCurrentPage] = useState(0)

  return (
    <Wrapper>
      <Sidebar>
        <Links>
          {content.map((page, index) => (
            <Link
              to={page.slug}
              key={page.id}
              onMouseEnter={() => setCurrentPage(index)}
            >
              <Title as="h3" isCurrent={currentPage === index}>
                {page.label}
                <sup>{index + 1}</sup>
              </Title>
            </Link>
          ))}
        </Links>
        <FooterHome />
      </Sidebar>
      <Content>
        <AnimatePresence>
          {content.map((page, index) => (
            <div key={page.id}>
              {index === currentPage && (
                <Image
                  background={page.homeImage.url}
                  initial={{ opacity: 0.75 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0.75 }}
                ></Image>
              )}
            </div>
          ))}
        </AnimatePresence>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`
const Sidebar = styled.div`
  padding: 3rem 10% 3rem 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`
const Content = styled.div`
  background: ${DesignTokens.colors.primary[500]};
  position: relative;
  width: 100%;
`

const Image = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
`

const Links = styled.div`
  a {
    display: block;
    text-decoration: none;
    color: ${DesignTokens.colors.secondary[500]};
    &:hover {
      color: ${DesignTokens.colors.primary[500]};
    }
  }
  & > * {
    margin-bottom: 4rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Title = styled(Display)`
  color: ${props =>
    props.isCurrent
      ? DesignTokens.colors.primary[500]
      : DesignTokens.colors.secondary[500]};
`

export default Slider
