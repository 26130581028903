import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Display } from "./typography"
import { Col, Container, Row } from "react-grid-system"

const MobileSlider = ({ content }) => (
  <Wrapper>
    <Container>
      <Row>
        <Col>
          {content.map((page, index) => (
            <CustomLink to={page.slug} key={page.id}>
              <Title as="h3">
                {page.label} <sup>{index + 1}</sup>
              </Title>
              <img src={page.homeImage.url} />
            </CustomLink>
          ))}
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

const CustomLink = styled(Link)`
  position: relative;
  display: block;
`

const Title = styled(Display)`
  position: absolute;
  width: 100%;
  top: 45%;
  text-align: center;
  color: white;
`

const Wrapper = styled.div``

const Images = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export default MobileSlider
